import MoteurDeRechercheDeDonneesReferentiel from "./MoteurDeRechercheDeDonneesReferentiel"
import { RéponseCommunes, ReponseUgle } from "./types/UgleReponseHttp"
import { LieuDeNaissance, RéponseCommunesDeNaissance } from "./types/LieuDeNaissanceReponseHttp"
import axios from "../Axios/AxiosApi"
import { BACKEND_URL } from "../configurationHttp"
import { PaysDeNaissance, RéponsePaysDeNaissance } from "./types/PaysDeNaissanceReponseHttp"
import { RéponseRNVP } from "./types/AdresseRnvpReponseHttp"
import { AdresseRedressée } from "./types/AdresseRedressée"
import { Suggestion } from "../../App/ui/ChampAutoCompletion/ChampAutoCompletion"

export const extraireNumeroDepartementEntreParentheses = (codeCommune: string): string => {
	if (codeCommune == null || codeCommune == "") return ""
	return " (" + extraitNumeroDepartement(codeCommune) + ")"
}

export function recupererNomCommuneEtCodePostalArrondissement(ville: string): string[] {
	const regexVilleAvecArrondissement = new RegExp(/(\w*) *([0-9]{1,2})(e|er) *Arrondissement/)
	const resultatRegex = regexVilleAvecArrondissement.exec(ville)
	if (resultatRegex == null) {
		return [ville, ""]
	}
	const villeSansArrondissement = resultatRegex[1].toUpperCase()
	const arrondissement = resultatRegex[2].toUpperCase()
	let cp = ""
	if (villeSansArrondissement === "PARIS") {
		if (parseInt(arrondissement) > 9) {
			cp = "750" + arrondissement
		} else {
			cp = "7500" + arrondissement
		}
	} else if (villeSansArrondissement === "MARSEILLE") {
		if (parseInt(arrondissement) > 9) {
			cp = "130" + arrondissement
		} else {
			cp = "1300" + arrondissement
		}
	} else if (villeSansArrondissement === "LYON") {
		cp = "6900" + arrondissement
	}

	return [villeSansArrondissement, cp]
}

export const normaliserNomDeCommune = (chaine: string): string => {
	const formeNormaleDecomposee = "NFD"
	const regexCaracteresDiacritiques = /[\u0300-\u036f]/g

	const chaineSimplifiee = chaine
		.toUpperCase()
		.replace(/[-']/g, " ")
		.normalize(formeNormaleDecomposee)
		.replace(regexCaracteresDiacritiques, "")
		.replace(/Œ/g, "OE")
		.replace(/Æ/g, "AE")
		.replace("SAINT ", "ST ")
		.replace("SAINTE ", "STE ")

	return chaineSimplifiee
}

function extraitNumeroDepartement(codeCommune: string) {
	if (codeCommune == null || codeCommune == "") return ""
	return (parseInt(codeCommune) < 96000 ? codeCommune.substring(0, 2) : codeCommune.substring(0, 3))
}

const RNVP_HEALTHCHECK_URL_PATH = "/rnvp/health"
const RNVP_REDRESSEMENT_ADRESSE_URL_PATH = "/rnvp/complete-address"

const MoteurDeRechercheDeDonnesReferentiel = (): MoteurDeRechercheDeDonneesReferentiel => {
	return {
		async completerUgle(extrait: string): Promise<Suggestion<ReponseUgle>[]> {
			if (!extrait) {
				return []
			}
			const { data } = await axios.get<RéponseCommunes>(`${BACKEND_URL}/referentiel/communes`, {
				params: {
					"mots-cles": extrait
				}
			})

			return data.communes.map(commune => ({
				key: commune.codeCommune,
				label: commune.libelleCommune + extraireNumeroDepartementEntreParentheses(commune.codeCommune),
				values: commune
			}))
		},
		async rechercherLieuxDeNaissance(extrait: string): Promise<Suggestion<LieuDeNaissance>[]> {
			if (!extrait) {
				return []
			}
			const { data } = await axios.get<RéponseCommunesDeNaissance>(`${BACKEND_URL}/referentiel/communes-de-naissance`, {
				params: {
					"mots-cles": extrait
				}
			})
			return data.communesDeNaissance.map(commune => ({
				key: commune.code,
				label: commune.libelle + extraireNumeroDepartementEntreParentheses(commune.code),
				values: commune
			}))
		},
		async rechercherPaysDeNaissance(extrait: string): Promise<Suggestion<PaysDeNaissance>[]> {
			if (!extrait) {
				return []
			}
			const { data } = await axios.get<RéponsePaysDeNaissance>(`${BACKEND_URL}/referentiel/pays-de-naissance`, {
				params: {
					"mots-cles": extrait
				}
			})
			return data.paysDeNaissance.map(pays => ({
				key: pays.code,
				label: pays.libelle,
				values: pays
			}))
		},
		async redresserAdresse(extrait: string, filtreCommune: string, filtreNumeroDepartement: string): Promise<Suggestion<AdresseRedressée>[]> {
			if (!extrait || extrait.length < 5) {
				return []
			}
			const villeEtArrondissement = recupererNomCommuneEtCodePostalArrondissement(filtreCommune)
			const ville = villeEtArrondissement[0]
			const arrondissement = villeEtArrondissement[1]

			const params =
				ville === "" ?
					{
						"language": "fr",
						"country": "fr",
						"in_line": extrait,
					} :
					{
						"language": "fr",
						"country": "fr",
						"in_line": extrait,
						"in_city": ville,
						"in_zip": arrondissement === "" ? extraitNumeroDepartement(filtreNumeroDepartement) : arrondissement,
						"par_reduce_hits": "0"
					}


			const { data } = await axios.get<RéponseRNVP>(`${BACKEND_URL}` + RNVP_REDRESSEMENT_ADRESSE_URL_PATH, {
				params: params
			})

			let listeAdressesTrouvees = data.content

			if (filtreCommune != "") {
				listeAdressesTrouvees = listeAdressesTrouvees.filter(adresseRnvp => normaliserNomDeCommune(adresseRnvp.out_city) == normaliserNomDeCommune(ville) || normaliserNomDeCommune(adresseRnvp.out_city_district) == normaliserNomDeCommune(ville))
			}
			listeAdressesTrouvees = listeAdressesTrouvees.filter(adresseRnvp => adresseRnvp.out_street !== "")
			return listeAdressesTrouvees
				.map(adresseRnvp => ({
					key: adresseRnvp.out_ref,
					label: adresseRnvp.out_hno + " " + adresseRnvp.out_street + " " + adresseRnvp.out_zip + " " + adresseRnvp.out_city,
					values: {
						ville: adresseRnvp.out_city,
						numéroDeVoie: adresseRnvp.out_hno,
						nomDeVoie: adresseRnvp.out_street,
						codePostal: adresseRnvp.out_zip,
					}
				}))
		},
		async verifierSiRNVPEstOk(): Promise<boolean> {
			try {
				await axios.get<RéponseRNVP>(`${BACKEND_URL}` + RNVP_HEALTHCHECK_URL_PATH)
				return true
			} catch (erreur) {
				return false
			}
		}
	}
}

export default MoteurDeRechercheDeDonnesReferentiel
